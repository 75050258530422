<template>
  <VueApexCharts
      type="bar"
      :height="height ? height : 350"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoBarra",
  components: { VueApexCharts },
  props: [
    "dataSeries",
    "categories",
    "height",
    "width",
    "colors",
    "columnWidth",
    "titlesY",
    "tooltipsY",
    "money",
    "porcentagem",
    "dataLabels",
    "legend",
    "xaxis"
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: "100%",
          width: "100%",
          events: {
            click: function (chart, w, e) {
              console.log(chart, w, e)
            }
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '80%',
            distributed: true,
            borderRadius: 8,
            dataLabels: {},
            endingShape: "rounded",
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.25,
            opacityFrom: 0.9,
            opacityTo: 0.95,
            stops: [0, 10, 100],
          },
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontWeight: "bold",
            }
          }
        },
        tooltip: {
          y: [],
        },
      },
    };
  },
  created() {
    this.series = [{data: this.dataSeries}];
    this.chartOptions.xaxis.categories = this.categories;

    // Configuração do tooltip baseada em props
    if (this.money) {
      this.chartOptions.tooltip.y = {
        formatter: (val) => {
          return parseFloat(val).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }
      };
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: (val) => {
          return parseFloat(val).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
        style: {
          fontSize: '16px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
          colors: ["#002D56"],
        },
        background: {
          enabled: true,
          borderColor: "#fff",
          opacity: 0.6,
        },
        offsetY: 0,
      };
      this.chartOptions.yaxis = {
        labels: {
          formatter: (val) => {
            return parseFloat(val).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            });
          },
          style: {
            fontSize: '12px',
            fontWeight: "bold",
          }
        }
      };
    } else if (this.porcentagem) {
      this.chartOptions.tooltip.y = {
        formatter: (val) => `${parseFloat(val).toFixed(2)}%`
      };
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: (val) => `${parseFloat(val).toFixed(2)}%`,
        style: {
          fontSize: '16px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
          colors: ["#002D56"],
        },
        background: {
          enabled: true,
          borderColor: "#fff",
          opacity: 0.6,
        },
        offsetY: 0,
      };
      this.chartOptions.yaxis = {
        labels: {
          formatter: (val) => `${parseFloat(val).toFixed(2)}%`,
          style: {
            fontSize: '12px',
            fontWeight: "bold",
          }
        }
      };
    } else {
      this.chartOptions.tooltip.y = {
        formatter: (val) => val.toString()
      };
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: (val) => val.toString()
      };
    }

    // Outras configurações
    if (this.colors) {
      this.chartOptions.colors = this.colors;
      this.chartOptions.xaxis.labels.style.colors = this.colors;
    }
    if (this.columnWidth) {
      this.chartOptions.plotOptions.bar.columnWidth = this.columnWidth;
    }
    if (this.legend) {
      this.chartOptions.legend = this.legend;
    }
    if (this.xaxis) {
      this.chartOptions.xaxis = this.xaxis;
    }
  }
};
</script>
