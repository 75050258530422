<template>
  <VueApexCharts
      type="bar"
      :height="height ? height : 350"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoColuna",
  components: { VueApexCharts },
  props: [
    "dataSeries",
    "dataSeries2", // Valores reais para tooltip quando perc_money é true
    "categories",
    "height",
    "width",
    "colors",
    "columnWidth",
    "titlesY",
    "tooltipsY",
    "money",
    "porcentagem",
    "perc_money",  // Nova prop para habilitar percentual no gráfico e reais no tooltip
    "dataLabels",
    "legend",
    "xaxis",
    "maximos"
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: "100%",
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '70%',
            borderRadius: 5,
            endingShape: "rounded",
            distributed: false,
            horizontal: false
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.25,
            opacityFrom: 0.9,
            opacityTo: 0.95,
            stops: [0, 10, 100],
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ["#002D56"],
          },
          background: {
            enabled: true,
            borderColor: "#fff",
            opacity: 0.6,
          },
          offsetY: 0,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontWeight: "bold",
            }
          },
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: [],
        },
        legend: {
          show: true,
          position: "bottom",
          markers: {
            width: 10,
            height: 10,
          }
        },
      },
    };
  },
  created() {
    // Carrega os valores percentuais para o gráfico
    this.series = this.dataSeries.map((serie) => ({
      name: serie.name,
      data: serie.data.map((value, i) => value / this.maximos[i])
    }));

    this.chartOptions.xaxis.categories = this.categories;

    if (this.perc_money && this.dataSeries2) {
      // Exibir percentual no gráfico e valores reais no tooltip para todas as séries de dataSeries2
      this.chartOptions.tooltip.y = {
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          const valorReal = this.dataSeries2[seriesIndex].data[dataPointIndex];
          return parseFloat(valorReal).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
      };

      this.chartOptions.dataLabels.formatter = (value, { dataPointIndex }) => {
        return `${(value * this.maximos[dataPointIndex]).toFixed(2)}%`;
      };
    } else if (this.money) {
      this.chartOptions.tooltip.y = {
        formatter: (val) => {
          return parseFloat(val).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
      };
      this.chartOptions.dataLabels.formatter = (val) => {
        return parseFloat(val).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      };
    } else if (this.porcentagem) {
      this.chartOptions.tooltip.y = {
        formatter: (value, { dataPointIndex }) => `${(value * this.maximos[dataPointIndex]).toFixed(2)}%`,
      };
      this.chartOptions.dataLabels.formatter = (value, { dataPointIndex }) => `${(value * this.maximos[dataPointIndex]).toFixed(2)}%`;
    } else {
      this.chartOptions.tooltip.y = {
        formatter: (val) => val.toString()
      };
      this.chartOptions.dataLabels.formatter = (val) => val.toString();
    }

    // Configurações opcionais
    if (this.colors) {
      this.chartOptions.colors = this.colors;
      if (this.dataSeries.length <= 1){
        this.chartOptions.xaxis.labels.style.colors = this.colors;
      }
    }
    if (this.columnWidth) {
      this.chartOptions.plotOptions.bar.columnWidth = this.columnWidth;
    }
    if (this.legend) {
      this.chartOptions.legend = this.legend;
    }
    if (this.xaxis) {
      this.chartOptions.xaxis = this.xaxis;
    }
  }
};
</script>
